import React from 'react';

import styled from 'styled-components';

import resume from '../img/my-pic-2.jpg';

const ImageSection =()=>{
return (
 <ImageSectionStyled>
  <div className='left-content'>
   <img src={resume}  alt=""/>
  </div>
  <div className='right-content'>
    <h4>I am <span>Olutomi Damilare</span></h4>
   <p className='paragraph'>
    I’ve been a web developer, and it’s always been on my mind to work in this field. I am passionate about creating beautiful websites that are easy to use and understand.
   </p>
   <div className='about'>
    <div className='about-info'>
     <div className='info-title'>
      <p>Full Name</p>
      <p>Nationality</p>
      <p>Languages</p>
      <p>Location</p>
      <p>Service</p>
     </div>
     <div className='info'>
      <p>: Olutomi Damilare</p>
      <p>: Nigeria</p>
      <p>: English, Yoruba</p>
      <p>: Lagos, Nigeria</p>
      <p>: Freelance</p>
     </div>
   </div>
  </div>
  {/* <PrimaryButton title={'Download CV'}/> */}
  </div>
 </ImageSectionStyled>
 )
}

const ImageSectionStyled = styled.div`
  margin-top: 3.5rem;
  display: flex;
  @media screen and (max-width: 1000px){
  flex-direction: column;
  .left-content{
  margin-bottom: 2rem;
  }
}
  .left-content{
    width: 100%;
    height: 90vh;
  img{
  width: 90%;
  height: 100%;
  object-fit: cover;
  }
  }
  .right-content{
    width: 100%;
    h4{
    font-size: 2rem;
    color: var(--white-color);
    span{
    font-size: 2rem;
    }
    }
    .paragraph{
    padding: 1rem 0;
    }
  .about-info{
  display: flex;
    padding-bottom: 1.4rem;
  .info-title{
  padding-right: 3rem;
  font-weight: 600;
  p{
  font-weight: 600;
  }
  }
  .info-title, .info{
  p{
  padding: .3rem 0;
  @media screen and (max-width: 375px){ 
  font-size: 1rem;
  }
  @media screen and (max-width: 280px){ 
  font-size: .8rem;
  }
  }
  }
  }
  }
`;

export default  ImageSection;