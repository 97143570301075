import React from 'react';

import styled from 'styled-components';

import Particle from '../Components/Particles';

function HomePage({setNavToggle, navToggle}){
return (
 <HomePageStyled onClick={()=> setNavToggle(navToggle !== navToggle)}>
    <div className='particle-con'>
     <Particle/>
    </div>
 <div className='typography'>
  <h1>Hi, I'm <span>Olutomi Damilare</span></h1>
  <p>  I’m a web developer who loves to make beautiful things. I love building websites that are easy to use and can be accessed by anyone. I also love making my clients happy with their site’s performance, so they can see it on any device they have.</p>
  <div className='icons'>
  <a href='https://web.facebook.com/daniel.olutomi/' className='icon i-facebook'><i class="fa-brands fa-square-facebook"></i></a>
  <a href='https://www.youtube.com/@Daniel-dan98' className='icon i-youtube'><i class="fa-brands fa-square-youtube"></i></a>
  </div>
 </div>
 </HomePageStyled>
 )
}

const HomePageStyled = styled.header`
 width: 100%;
 height: 100vh;
 position: relative;
 
 .particle-con{
 position: absolute;
 top: 0;
}
 .typography{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 text-align: center;
 width: 80%;
 .icons{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
 .icon{
     border: 2px solid var(--border-color); 
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 50%;
     transition: all .4s ease-in-out;
     cursor: pointer;
     &:hover{
     border: 2px solid var(--primary-color); 
     color: var(--primary-color);
     }
     &:not(:last-child){
     margin-right: 1rem;
     }
     .fa-brands{
     margin: .5rem;
     }
 }
 .i-youtube{
 &:hover{
     color: red;
     border: 2px solid red;
     }
 }
 .i-github{
 &:hover{
     color: purple;
     border: 2px solid purple;
     }
 }
 }
 }
`;

export default HomePage;